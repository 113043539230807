import React from 'react'
import Helmet from 'react-helmet'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import '../styles/all.scss'

const TemplateWrapper = ({ children, removePadding }) => (
  <div
    className={`site-wrapper ${
      removePadding ? 'no-padding navbar-transparent' : 'page-padding'
    }`}
  >
    <Header />
    <div className={`site-content ${removePadding ? '' : 'py-4 md:py-10'}`}>
      {children}
    </div>
    <Footer />
  </div>
)

export default TemplateWrapper
