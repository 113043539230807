import React from 'react'
import { Link } from 'gatsby'
import './Logo.scss'

const Logo = ({ type, toggle }) => {
  return (
    <Link
      to="/"
      className="logo transition-all mx-auto xl:mx-0"
      onClick={() => toggle(true)}
    >
      {type === 'header' && <h1>PNPR</h1>}
      {type === 'footer' && <h5 className="footer mb-8">PNPR</h5>}
    </Link>
  )
}

export default Logo
