import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { v4 } from 'uuid'
import { IoIosMenu, IoIosClose } from 'react-icons/io'
import { FiChevronRight, FiMail } from 'react-icons/fi'
import Logo from '../Logo/Logo'
import Navbar from '../Navbar/Navbar'
import './Header.scss'

const MenuButton = ({ menuOpen }) => {
  if (menuOpen) {
    return <IoIosClose />
  }

  return <IoIosMenu />
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileMenuOpen: false,
    }
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
  }

  toggleMobileMenu(close) {
    const element = document.getElementsByTagName('body')[0]

    this.setState(state => {
      const menuOpen = close ? false : !state.mobileMenuOpen
      if (menuOpen) {
        element.classList.add('menu-open')
      } else {
        element.classList.remove('menu-open')
      }
      return {
        mobileMenuOpen: menuOpen,
      }
    })
  }

  render() {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const menu = data.allWordpressWpApiMenusMenusItems.edges[0].node
          return (
            <header className="site-header z-20 transition-all">
              <div className="header-top flex items-center md:flex-end">
                <div className="header-left w-full block md:hidden">
                  <div
                    className={` h-full menu-toggle ${
                      this.state.mobileMenuOpen ? 'menu-open' : ''
                    } md:hidden`}
                  >
                    <button
                      onClick={() => this.toggleMobileMenu(false)}
                      className="mobile-menu-toggle"
                    >
                      <MenuButton menuOpen={this.state.mobileMenuOpen} />
                    </button>
                  </div>
                </div>
                <div className="header-center w-full md:w-auto text-center md:text-left md:flex px-6">
                  <Logo type="header" toggle={this.toggleMobileMenu} />
                </div>
                <div className="header-right w-full h-full items-center text-right">
                  <div className="navbar h-full">
                    <Navbar
                      links={menu.items}
                      mobileMenuOpen={this.state.mobileMenuOpen}
                      toggleMobileMenu={this.toggleMobileMenu}
                    />
                  </div>
                  <Link
                    to="/contact"
                    className="h-full mobile-contact-button justify-end text-2xl"
                    onClick={() => this.toggleMobileMenu(true)}
                  >
                    <FiMail />
                  </Link>
                </div>
              </div>
            </header>
          )
        }}
      />
    )
  }
}

export default Header

const query = graphql`
  query HeaderMenu {
    allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "primary" } }) {
      edges {
        node {
          name
          slug
          count
          items {
            order
            wordpress_id
            title
            object_slug
            classes
            wordpress_children {
              wordpress_id
              title
              object_slug
              object
              url
            }
          }
        }
      }
    }
  }
`
