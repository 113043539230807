export const replaceURL = url => {
  return url
    .replace('https://admin.pnprlimited.co.uk/', '')
    .replace('http://admin.pnprlimited.co.uk/', '')
}

export const getURL = item => {
  if (item.object === 'category') {
    return item.url.replace('https://admin.pnprlimited.co.uk', '')
  }
  if (item.object_slug === 'home') {
    return '/'
  }
  return `/${item.object_slug}`
}
