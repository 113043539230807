import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { v4 } from 'uuid'
import './Footer.scss'
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { MdEmail, MdPhone } from 'react-icons/md'
import { find } from 'lodash'
import Logo from '../Logo/Logo'
import { getURL } from '../../utils'
import CIPRLogo from '../../img/cipr-fellow-logo.png'

const FooterMenu = props => {
  return (
    <ul className="footer-menu">
      {props.menu.items.map(link => (
        <li key={link.wordpress_id}>
          <Link to={getURL(link)}>
            <span dangerouslySetInnerHTML={{ __html: link.title }} />
          </Link>
        </li>
      ))}
    </ul>
  )
}

const Footer = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={data => {
      const { node: pnprMenu } = find(
        data.allWordpressWpApiMenusMenusItems.edges,
        menu => menu.node.slug === 'footer-pnpr-limited'
      )
      const { node: servicesMenu } = find(
        data.allWordpressWpApiMenusMenusItems.edges,
        menu => menu.node.slug === 'footer-our-services'
      )
      return (
        <footer className="footer-wrapper">
          <div className="container">
            <div className="flex flex-wrap">
              <div className="w-full xl:w-5/12 mb-8 xl:mb-0 sm:text-center xl:text-left">
                <Logo type="footer" />
                <a href="http://www.cipr.co.uk/" target="_blank">
                  <img
                    className="inline-block mr-4"
                    src={CIPRLogo}
                    alt="CIPR Fellows Logo"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/pennynorton"
                  target="_blank"
                >
                  <FaLinkedinIn className="inline-block mr-4 text-3xl" />
                </a>
                <a href="https://twitter.com/penny_norton" target="_blank">
                  <FaTwitter className="inline-block text-3xl" />
                </a>
              </div>
              <div className="w-full sm:w-1/3 xl:w-2/12 mb-8 xl:mb-0">
                <h5 className="underlined pink-underline centered-mobile">
                  PNPR Limited
                </h5>
                <FooterMenu menu={pnprMenu} />
              </div>
              <div className="w-full sm:w-1/3 xl:w-2/12 mb-8 xl:mb-0">
                <h5 className="underlined pink-underline centered-mobile">
                  Our Services
                </h5>
                <FooterMenu menu={servicesMenu} />
              </div>
              <div className="w-full sm:w-1/3 xl:w-3/12 content">
                <h5 className="underlined pink-underline centered-mobile">
                  Contact Us
                </h5>
                <p>
                  Please get in touch if you'd like to find out more about PNPR
                  and examples of the company's work.
                </p>
                <p>
                  You can also follow Penny on{' '}
                  <a href="https://twitter.com/penny_norton" target="_blank">
                    Twitter
                  </a>{' '}
                  or find out more on{' '}
                  <a
                    href="https://www.linkedin.com/in/pennynorton"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                  .
                </p>
                <p>
                  <MdPhone className="mr-4 text-2xl inline-block" />
                  07930 315142
                </p>
                <p>
                  <Link to="/contact">
                    <MdEmail className="mr-4 text-2xl inline-block" />
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
            <div className="flex flex-wrap text-xs mt-12 font-display">
              <div className="hidden xl:flex xl:w-5/12" />
              <div className="w-full sm:w-1/3 xl:w-2/12">
                &copy; {new Date().getFullYear()} PNPR Limited
              </div>
              <div className="w-full sm:w-1/3 xl:w-2/12 legal-links">
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </div>
              <div className="w-full sm:w-1/3 xl:w-3/12 sm:text-right">
                <a href="https://www.dhdesigns.uk" target="_blank">
                  Website DH Designs
                </a>
              </div>
            </div>
          </div>
        </footer>
      )
    }}
  />
)

export default Footer

const query = graphql`
  query FooterMenus {
    allWordpressWpApiMenusMenusItems {
      edges {
        node {
          name
          slug
          count
          items {
            order
            wordpress_id
            title
            object_slug
            object
            url
          }
        }
      }
    }
  }
`
