import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'
import RightArrow from '../../img/arrow-right.svg'
import UpArrow from '../../img/arrow-up.svg'
import { getURL } from '../../utils'
import './Navbar.scss'

class Navbar extends React.Component {
  openDropdown(e, id) {
    // Only allow top-level click on non-mobile devices
    if (window.innerWidth < 768) {
      e.preventDefault()
      const element = document.getElementById(id)
      if (element.classList.contains('is-open')) {
        element.classList.remove('is-open')
      } else {
        element.classList.add('is-open')
      }
    }
  }

  render() {
    return (
      <div
        className={`navbar-wrapper h-full ${
          this.props.mobileMenuOpen ? 'menu-open' : ''
        } `}
      >
        <ul className="main-nav h-full md:flex items-center justify-end">
          {this.props.links.map((l, i) => {
            if (l.wordpress_children) {
              return (
                <li
                  key={v4()}
                  id={l.wordpress_id}
                  className={`md:text-center md:h-full font-sans antialiased has-dropdown ${l.classes}`}
                >
                  <Link
                    to={getURL(l)}
                    onClick={e => this.openDropdown(e, l.wordpress_id)}
                    className="p-4 block transition-bg transition-100"
                    activeClassName="active"
                  >
                    <span>{l.title}</span>
                    <img
                      className="up-arrow md:hidden"
                      alt="Navigation arrow up"
                      src={UpArrow}
                    />
                  </Link>
                  <div className="mega-menu flex hidden bg-gray-900 md:bg-white">
                    <ul>
                      {l.wordpress_children.map(sl => (
                        <li key={v4()}>
                          <Link
                            to={getURL(sl)}
                            onClick={() => this.props.toggleMobileMenu(true)}
                            className="px-2 block transition-bg transition-100"
                          >
                            {sl.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              )
            }
            return (
              <li
                key={v4()}
                className="md:text-center md:h-full md:flex align-center font-sans antialiased"
              >
                <Link
                  to={getURL(l)}
                  onClick={() => this.props.toggleMobileMenu(true)}
                  className="p-4 block transition-bg transition-100"
                  activeClassName="active"
                >
                  <span>{l.title}</span>
                  <img
                    className="right-arrow md:hidden"
                    alt="Navigation arrow right"
                    src={RightArrow}
                  />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default Navbar
